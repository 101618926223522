import Flex from '@common-components/blocs/Flex'
import Calendar from '@common-components/calendar/Calendar'
import CalendarBody from '@common-components/calendar/CalendarBody'
import Grid from '@common-components/containers/Grid'
import OrderSquares from '@common-components/services/Squares'
import { POLLING_INTERVAL } from '@common/constants'
import BookPlanningConvoy from '@components/BookPlanningConvoy'
import DashboardHeaderCalendar from '@components/dashboard/Header'
import { useGetFuneralPlanningQuery } from '@redux/features/funeral'
import { Typography } from 'antd'
import { useRouter } from 'next/router'
import React from 'react'

const Dashboard = () => {
    const { push } = useRouter()

    const [cursorDate, setCursorDate] = React.useState({
        start: null,
        end: null,
    })

    const handleBookingDate = (slot) => {
        push({ pathname: '/orders/convoys', query: { av_id: slot.id } })
    }

    return (
        <div className="orders-convoy-create">
            <BookPlanningConvoy handleBookingDate={handleBookingDate} />
        </div>
    )
}

export default Dashboard
