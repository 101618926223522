import Flex from '@common-components/blocs/Flex'
import Calendar from '@common-components/calendar/Calendar'
import CalendarBody from '@common-components/calendar/CalendarBody'
import Card from '@common-components/containers/Card'
import { DATE_FORMAT } from '@common/constants'
import { useGetConvoyPlanningQuery } from '@redux/features/services/convoy'
import { Button, Skeleton, DatePicker, Typography } from 'antd'
import dayjs from 'dayjs'

import React from 'react'
import Grid from '@common-components/containers/Grid'
import _ from 'lodash'

const BookPlanningConvoy = ({ handleBookingDate }) => {
    const [cursorDate, setCursorDate] = React.useState({
        start: dayjs().format(DATE_FORMAT.api),
        end: dayjs().add(6, 'days').format(DATE_FORMAT.api),
    })

    const { data: planning, isLoading: isLoadingGetAvailability, isSuccess: isSuccessGetAvailability } = useGetConvoyPlanningQuery(cursorDate, { refetchOnMountOrArgChange: true })

    // Handle date picker changed
    const onDatePicked = (date) => {
        _.isNil(date) ? setCursorDate({ start: null }) : setCursorDate({ start: dayjs(date).format(DATE_FORMAT.api) })
    }

    return (
        <Card>
            <Grid
                template={[
                    { xs: '24', md: '24', lg: '12', xl: '12', xxl: '12' },
                    { xs: '24', md: '24', lg: '12', xl: '12', xxl: '12' },
                ]}
                className="date-picker-convoy-container"
            >
                <Typography.Title level={2}>Choisissez la date des obsèques pour réserver le convoi</Typography.Title>
                <DatePicker value={cursorDate?.start ? dayjs(cursorDate?.start) : null} format={'DD/MM/YYYY'} size="large" picker="week" onChange={onDatePicked} className="date-picker-convoy" />
            </Grid>

            {isLoadingGetAvailability && !isSuccessGetAvailability ? (
                <Skeleton active />
            ) : (
                <Calendar cursorDate={cursorDate} setCursorDate={setCursorDate} planning={planning} footerType="convoy">
                    <CalendarBody
                        planning={planning}
                        dayAccesskey="availabilities"
                        renderItem={(slot, i) => {
                            const isDatePassed = dayjs(slot?.day).isBefore(dayjs(), 'day')

                            const classNameIsDatePassed = isDatePassed ? 'isDatePassed' : ''

                            return (
                                <Flex fullHeight fullWidth justify="center" className={[classNameIsDatePassed, slot?.occupancy_rate, 'slot dashboard'].join(' ')} onClick={() => {
                                    if (!isDatePassed && slot?.occupancy_rate !== 'full') {
                                        handleBookingDate(slot)
                                    }
                                }}>
                                    {!isDatePassed && slot?.occupancy_rate !== 'full' && <Button type="secondary">Réserver</Button>}
                                    {slot?.occupancy_rate == 'full' && (
                                        <div>
                                            <p>Contactez-nous</p>
                                            <a href="tel:0973386596" className="ant-btn ant-btn-secondary">
                                                0973386596
                                            </a>
                                        </div>
                                    )}
                                </Flex>
                            )
                        }}
                    />
                </Calendar>
            )}
        </Card>
    )
}
export default BookPlanningConvoy
