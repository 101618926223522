import { Card as CardAntd, Divider, Typography } from 'antd'
import _ from 'lodash'
import { memo } from 'react'

import Flex from '../blocs/Flex'

const { Title } = Typography

const Card = ({ title, icon, children, loading, className = '', ...props }) => {
    const renderTitle = () => {
        if (_.isNil(title) && _.isNil(icon)) {
            return null
        }

        if (_.isNil(icon)) {
            return <Title level={2} variant="black" children={title} />
        } else {
            return (
                <Flex>
                    {icon} <Title level={2} variant="black" children={title} />
                </Flex>
            )
        }
    }

    return (
        <CardAntd
            {...props}
            loading={loading}
            className={className + ' containerCard'}
            title={renderTitle()}
        >
            {!title ?? <Divider hidden />}

            {children}
        </CardAntd>
    )
}

export default memo(Card)
